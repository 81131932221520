module.exports = [{
      plugin: require('../plugins/gatsby-plugin-primary-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-root-and-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-information/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"fi"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anora Pro","short_name":"Anora Pro","start_url":"/","background_color":"#ffffff","theme_color":"#1b1d36","icon":"src/images/anoraPro/favicon.png","include_favicon":false,"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"ab477325fb8f5a57c201864c547f4ee5"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
